<template>
    <div>
        <pretty-select
            v-if="items"
            placeholder="Select agreement"
            label="agreementName"
            :options="items"
            v-model="selectedAgreement"
            :required="required"
            :reduce="reducedPayload"
            data-test="agreementSelect"
        ></pretty-select>
    </div>
</template>

<script>
import PrettySelect from '../../components/utils/PrettySelect'
import CONFIG from '@root/config'

export default {
    name: 'AgreementSelect',
    components: { PrettySelect },
    data () {
        return {
            items: null,
        }
    },
    props: {
        required: {
            default: false,
        },
        returnField: {
            default: null,
        },
        agreement: {
            default: null,
        },
    },
    model: {
        prop: 'agreement',
        event: 'agreementChange',
    },
    computed: {
        selectedAgreement: {
            get () {
                return this.agreement
            },
            set (value) {
                this.$emit('agreementChange', value)
            },
        },
        reducedPayload () {
            if (this.returnField) {
                return (e) => e[this.returnField]
            }

            return e => e
        },
    },
    created () {
        const url = CONFIG.API.ROUTES.AGREEMENTS.OVERVIEW
        const requestPayload = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))
        if (!this.isAllowedTo('getAllAgreements')) {
            return
        }

        this.addJob(url)
        this.$api.post(url, requestPayload).then(({ data }) => {
            this.$emit('fetchedAgreements')
            this.items = data.data.items
        }).catch(err => {
            this.$root.$emit('notification:global', {
                message: err,
                type: 'error',
            })
        }).finally(() => {
            this.finishJob(url)
        })
    },
}
</script>
