<template>
    <mercur-dialog width="800px" :is-open.sync="isOpen" @close="emitClosed">
        <div slot="header">
            <h2 class="font-weight-normal mt-2" v-if="template">Regenerate pricing for {{ template.templateName }}</h2>
        </div>
        <div slot="default">
            <div class="row mt-3" v-if="template">
                <div class="col-12 mb-4">
                    <agreement-product-select
                        :agreementId="template.agreementId"
                        returnField="productConfigurationTemplateId"
                        :disabled="loading"
                        v-model="form.products"
                        :multiple="true" />
                </div>
                <div class="col-12 mb-4">
                    <country-selector v-model="form.countries" :disabled="loading" :agreementId="template.agreementId"></country-selector>
                </div>
            </div>
        </div>
        <div slot="footer" class="mb-2">
            <mercur-button class="btn" :disabled="loading" @click="emitClosed">Cancel</mercur-button>
            <mercur-button data-test="PriceGeneratorModalGenerateButton" class="btn btn-raised btn-primary" @click="submit" :disabled="loading">Regenerate prices</mercur-button>
        </div>
    </mercur-dialog>
</template>

<script>
import PricingHelperMethods from './PricingHelperMethods'
import AgreementProductSelect from '../agreements/AgreementProductSelect'
import CountrySelector from './CountrySelector'
import CONFIG from '@root/config'

export default {
    name: 'PriceGeneratorModal',
    mixins: [PricingHelperMethods],
    components: { AgreementProductSelect, CountrySelector },
    props: {
        template: {
            default: null,
        },
        isOpen: {
            default: false,
        },
    },
    data () {
        return {
            loading: false,
            form: {},
        }
    },
    methods: {
        emitClosed () {
            this.$set(this, 'form', {})
            this.$emit('close')
        },
        submit () {
            if (!this.form.products && !this.form.countries) {
                this.$root.$emit('notification:global', {
                    message: 'Please select at least one product template',
                    type: 'error',
                })
                return false
            }

            const url = CONFIG.API.ROUTES.PRICING.PRICING_RULE_TEMPLATES.GENERATE_SALES_PRICES
                .replace('{pricingRuleTemplateId}', this.template.pricingRuleTemplateId)
                .replace('{agreementId}', this.template.agreementId)

            this.loading = true
            this.$api.post(url, this.form).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Prices are being regenerated...',
                    type: 'success',
                })
                this.emitClosed()
                this.$emit('refresh')
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong.',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
    },
}
</script>
