<template>
    <mercur-card class="fill full-height-layout mb-3 mx-4">
        <grid-header :big-headline="true">
            Pricing Rule Templates
            <div class="row d-flex align-items-center pt-2 pl-2">
                <agreement-select class="agreement-select" v-model="form.agreementId" :required="true" returnField="agreementId"></agreement-select>
            </div>
            <template slot="actions">
                <mercur-button data-test="pricingRuleTemplatesAddButton" v-if="form.agreementId" @click="triggerAdd" class="btn btn-primary btn-raised">
                    <span>Add new pricing rule template</span>
                </mercur-button>
            </template>
        </grid-header>
        <div class="border fill full-height-layout" v-if="form.agreementId">
            <data-table
                class="fill full-height-layout border"
                ref="grid"
                :options="options"
                :url="url"
                data-test="pricingRuleTemplatesTable"
            ></data-table>
        </div>

        <portal to="sidebarRight">
            <transition name="slide-fade" @after-leave="onSidebarHidden">
                <router-view
                    ref="routeview"
                    class="sidebarRight"
                    :template-name="taskTemplateName"
                ></router-view>
            </transition>
        </portal>

        <price-generator-modal
            :template="regenerateTemplate"
            :is-open="isOpenGenerate"
            @close="closeGenerate"
            @refresh="refreshTable"
        />

        <mercur-dialog data-test="pricingRuleTemplatesAddModal" :is-open.sync="isOpen">
            <template slot="default">
                <h2 class="font-weight-normal">{{ isEdit ? 'Edit pricing rule template' : 'Add new pricing rule template'}}</h2>
                <mercur-input data-test="pricingRuleTemplatesNameInput" v-model.trim="form.templateName" :class="{'form-invalid': $v.form.templateName.$error}">
                    Template name
                    <template slot="note">
                        <span class="form-error" v-if="!$v.form.templateName.required">Template name is required</span>
                    </template>
                </mercur-input>
            </template>
            <div slot="footer" class="mb-2">
                <mercur-button class="btn btn-raised" :disabled="loading" @click="triggerClose">Cancel</mercur-button>
                <mercur-button data-test="pricingRuleTemplatesSaveButton" class="btn btn-primary btn-raised" @click="submit" :disabled="loading">
                    {{ isEdit ? 'Update template' : 'Add new template'}}
                </mercur-button>
            </div>
        </mercur-dialog>

        <mercur-dialog :is-open.sync="isOpenNotCoveredProducts" width="70vw">
            <template slot="default">
                <h2 class="font-weight-normal mt-1">List of products not covered by the template</h2>
                <mercur-spinner v-if="!notCoveredProducts"/>
                <div class="fhf" v-else>
                    <span v-for="product in notCoveredProducts" :key="product">{{ product }}<br/></span>
                </div>
            </template>
            <template slot="footer">
                <mercur-button class="btn" @click="isOpenNotCoveredProducts = false">Close</mercur-button>
            </template>
        </mercur-dialog>

        <mercur-dialog :is-open.sync="isOpenDuplicate">
            <template slot="default">
                <h2 class="font-weight-normal mb-3 mt-1">Duplicate template</h2>
                <p>You are duplicating template <strong>{{ draft.templateName }}</strong>.</p>
                <mercur-input id="duplicateTitle" v-model="duplicateName">
                    Duplicate name
                </mercur-input>
            </template>
            <template slot="footer">
                <mercur-button class="btn" @click="closeDuplicate" :disabled="loading">Cancel</mercur-button>
                <mercur-button class="btn btn-raised btn-primary" @click="duplicateTemplate" :disabled="loading">Duplicate</mercur-button>
            </template>
        </mercur-dialog>

        <mercur-dialog :is-open.sync="isDeleteDialogOpen">
            <template slot="default">
                <p>Are you sure you want to remove this template?</p>
            </template>
            <template slot="footer">
                <mercur-button class="btn" @click="triggerClose" :disabled="loading">Cancel</mercur-button>
                <mercur-button class="btn btn-raised btn-danger" @click="remove" :disabled="loading">Delete pricing rule template</mercur-button>
            </template>
        </mercur-dialog>

        <mercur-dialog :is-open.sync="isPutActionDialog">
            <template slot="default">
                <p>Are you sure you want to put this template to {{isStaging ? 'staging' : 'production'}}?</p>
            </template>
            <template slot="footer">
                <mercur-button class="btn btn-raised" @click="triggerClose" :disabled="loading">Cancel</mercur-button>
                <mercur-button class="btn btn-raised btn-primary" @click="deployTemplate" :disabled="loading">Confirm</mercur-button>
            </template>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import GridHeader from '@/components/GridHeader'
import DataTable from '../../components/DataTable'
import AgreementSelect from '../../components/agreements/AgreementSelect'
import PriceGeneratorModal from '../../components/pricing/PriceGeneratorModal'
import CONFIG from '@root/config'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'PricingRuleTemplates',
    components: { GridHeader, DataTable, AgreementSelect, PriceGeneratorModal },
    data () {
        return {
            options: {
                columns: {
                    'Template Name': { field: 'templateName' },
                    'Status': { field: 'templateStatus', statusChip: true },
                    'Is Updated': { field: 'updated',
                        width: 100,
                        cellRenderer: ({ data }) => {
                            return data.updated ? '<span class="check-unicode">&#x2713;</span>' : ''
                        } },
                    'Created': { field: 'dateCreated', sortable: true },
                },
                actions: [
                    {
                        icon: 'fas fa-hourglass-half',
                        isIcon: true,
                        text: 'Display tasks',
                        to: ({ data }) => {
                            return {
                                name: 'TemplateTasksOverview',
                                params: {
                                    ...this.$route.params,
                                    agreementId: this.form.agreementId,
                                    pricingRuleTemplateId: data.pricingRuleTemplateId,
                                },
                            }
                        },
                    },
                    {
                        text: 'Edit',
                        disabled: ({ data }) => this.loading || !this.isAllowedTo('SupplierCentral/updatePricingRuleTemplate'),
                        onClick: ({ data }) => this.triggerEdit(data),
                    },
                    {
                        text: 'Regenerate prices for rules',
                        disabled: ({ data }) => this.loading || !this.isAllowedTo('SupplierCentral/generateSalesPrices'),
                        onClick: ({ data }) => this.triggerRegenerate(data),
                    },
                    {
                        text: 'Duplicate template',
                        disabled: ({ data }) => this.loading || !this.isAllowedTo('SupplierCentral/duplicatePricingRuleTemplate'),
                        onClick: ({ data }) => this.triggerDuplicate(data),
                    },
                    {
                        text: 'Delete',
                        disabled: ({ data }) => this.loading || !this.isAllowedTo('SupplierCentral/deletePricingRuleTemplate'),
                        onClick: ({ data }) => this.triggerRemove(data),
                    },
                    {
                        text: 'Put to staging',
                        disabled: ({ data }) => this.loading || !this.isAllowedTo('SupplierCentral/putPricingTemplateToStaging') || !['OPEN', 'ARCHIVED'].includes(data.templateStatus),
                        onClick: ({ data }) => {
                            this.isStaging = true
                            this.triggerPutAction(data)
                        },
                    },
                    {
                        text: 'Put to production',
                        disabled: ({ data }) => this.loading || !this.isAllowedTo('SupplierCentral/putPricingTemplateToProduction') || data.templateStatus !== 'STAGING',
                        onClick: ({ data }) => {
                            this.isStaging = false
                            this.triggerPutAction(data)
                        },
                    },
                    {
                        text: 'Logs',
                        onClick: ({ data }) => {
                            this.showLogs(data)
                        },
                    },
                    {
                        text: 'Show not covered products',
                        onClick: ({ data }) => {
                            this.getProductsNotCovered(data)
                        },
                    },
                ],
            },
            isOpen: false,
            isEdit: false,
            loading: false,
            draft: {},
            isDeleteDialogOpen: false,
            isPutActionDialog: false,
            selectedRule: {},
            isStaging: false,
            form: {
                templateName: '',
                agreementId: null,
            },
            taskTemplateName: null,
            regenerateTemplate: null,
            isOpenGenerate: false,
            isOpenNotCoveredProducts: false,
            notCoveredProducts: null,
            isOpenDuplicate: false,
            duplicateName: null,
        }
    },
    validations: {
        form: {
            templateName: {
                required,
            },
            agreementId: {
                required,
            },
        },
    },
    computed: {
        url () {
            return CONFIG.API.ROUTES.PRICING.PRICING_RULE_TEMPLATES.LIST.replace('{agreementId}', this.form.agreementId)
        },
    },
    watch: {
        'form.agreementId' (value) {
            if (value === this.$route.params.agreementId) {
                return
            }
            this.$router.push({
                name: 'PricingRuleTemplates',
                params: {
                    agreementId: value,
                },
            })
        },
    },
    methods: {
        triggerAdd () {
            this.draft = {}
            this.isOpen = true
            this.isEdit = false
        },
        triggerEdit (data) {
            this.draft = JSON.parse(JSON.stringify(data))
            this.form = this.draft
            this.isOpen = true
            this.isEdit = true
        },
        triggerClose () {
            this.draft = {}
            this.isOpen = false
            this.isEdit = false
            this.isDeleteDialogOpen = false
            this.form.templateName = ''
            this.isPutActionDialog = false
        },
        triggerPutAction (data) {
            this.isPutActionDialog = true
            this.selectedRule = data
        },
        triggerRemove (data) {
            this.draft = JSON.parse(JSON.stringify(data))
            this.isDeleteDialogOpen = true
        },
        remove () {
            let url = CONFIG.API.ROUTES.PRICING.PRICING_RULE_TEMPLATES.DELETE
            this.loading = true
            this.$api.post(url, this.draft).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Template was deleted',
                    type: 'success',
                })
                this.$refs.grid.refreshGrid()
                this.triggerClose()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        deployTemplate () {
            let url = CONFIG.API.ROUTES.PRICING.PRICING_RULE_TEMPLATES.PUT_TO_STAGING.replace('{pricingRuleTemplateId}', this.selectedRule.pricingRuleTemplateId)
            if (!this.isStaging) {
                url = CONFIG.API.ROUTES.PRICING.PRICING_RULE_TEMPLATES.PUT_TO_PRODUCTION.replace('{pricingRuleTemplateId}', this.selectedRule.pricingRuleTemplateId)
            }

            this.loading = true
            this.$api.post(url, {}).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: this.isStaging ? 'Template was put to staging' : 'Template was put to production',
                    type: 'success',
                })
                this.$refs.grid.refreshGrid()
                this.triggerClose()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        submit () {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }

            let url = CONFIG.API.ROUTES.PRICING.PRICING_RULE_TEMPLATES.CREATE
            if (this.isEdit) {
                url = CONFIG.API.ROUTES.PRICING.PRICING_RULE_TEMPLATES.UPDATE
            }

            this.loading = true
            let payload = this.form
            if (!this.isEdit) {
                payload.templateStatus = 'OPEN'
            }

            this.$api.post(url, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: this.isEdit ? 'Template was updated' : 'Template was added',
                    type: 'success',
                })
                this.$refs.grid.refreshGrid()
                this.triggerClose()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        onSidebarHidden () {
            this.$bus.$emit('sidebar-hidden')
        },
        showLogs (data) {
            this.taskTemplateName = data.templateName
            this.$router.push({
                name: 'TemplateLogs',
                params: {
                    ...this.$route.params,
                    agreementId: this.form.agreementId,
                    pricingRuleTemplateId: data.pricingRuleTemplateId,
                },
            })
        },
        triggerRegenerate (data) {
            this.regenerateTemplate = data
            this.$set(this, 'isOpenGenerate', true)
        },
        closeGenerate () {
            this.regenerateTemplate = null
            this.$set(this, 'isOpenGenerate', false)
        },
        triggerDuplicate (data) {
            this.draft = data
            this.$set(this, 'isOpenDuplicate', true)
        },
        closeDuplicate () {
            this.draft = {}
            this.$set(this, 'isOpenDuplicate', false)
            this.duplicateName = null
        },
        duplicateTemplate () {
            this.loading = true
            const url = CONFIG.API.ROUTES.PRICING.PRICING_RULE_TEMPLATES.DUPLICATE
            const payload = {
                pricingRuleTemplateId: this.draft.pricingRuleTemplateId,
            }

            if (this.duplicateName) {
                payload.templateName = this.duplicateName
            }
            this.$api.post(url, payload).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Template was duplicated',
                    type: 'success',
                })
                this.$refs.grid.refreshGrid()
                this.closeDuplicate()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        refreshTable () {
            this.$refs.grid.refreshGrid()
        },
        getProductsNotCovered (data) {
            this.$set(this, 'notCoveredProducts', null)
            this.isOpenNotCoveredProducts = true
            const url = CONFIG.API.ROUTES.PRICING.PRICING_RULE_TEMPLATES.GET_NOT_COVERED_PRODUCTS.replace('{pricingRuleTemplateId}', data.pricingRuleTemplateId)
            this.$api.get(url).then(({ data }) => {
                data.data.sort()
                this.$set(this, 'notCoveredProducts', data.data)
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            })
        },
    },
    created () {
        if (this.$route.params.agreementId) {
            this.$set(this.form, 'agreementId', this.$route.params.agreementId)
        }
    },
}
</script>

<style lang="scss" scoped>
    .agreement-select {
        display: inline-block;
        width: 300px;
        margin-left: 10px;
        vertical-align: middle;
    }
</style>
