<template>
    <div>
        <mercur-spinner data-test="countrySelectorSpinner" v-if="!items"></mercur-spinner>
        <pretty-select
            data-test="countrySelector"
            v-if="items"
            placeholder="Select countries"
            label="countryName"
            :options="listOfCountries"
            v-model="selectedCountry"
            :required="required"
            :reduce="country => country.countryCode"
            :multiple="multiple"
            :disabled="disabled"
            :color="color"
        ></pretty-select>
    </div>
</template>

<script>
import PrettySelect from '../../components/utils/PrettySelect'
import CONFIG from '@root/config'
import countries from 'country-region-data'

export default {
    name: 'CountrySelector',
    components: { PrettySelect },
    data () {
        return {
            items: null,
            countries,
        }
    },
    props: {
        agreementId: {
            required: true,
        },
        required: {
            default: false,
        },
        returnField: {
            default: null,
        },
        country: {
            default: null,
        },
        multiple: {
            default: true,
        },
        disabled: {
            default: false,
        },
        color: {
            default: null,
        },
        initialCountries: {
            default: () => [],
        },
    },
    model: {
        prop: 'country',
        event: 'countryChange',
    },
    computed: {
        selectedCountry: {
            get () {
                return this.country
            },
            set (value) {
                this.$emit('countryChange', value)
            },
        },
        listOfCountries () {
            const list = []
            this.countries.forEach((country) => {
                if (this.items.includes(country.countryShortCode)) {
                    list.push({
                        countryName: country.countryName,
                        countryCode: country.countryShortCode,
                    })
                }
            })

            return list
        },
    },
    created () {
        if (this.initialCountries && this.initialCountries.length) {
            this.$emit('fetched', this.initialCountries)
            this.items = this.initialCountries
            return
        }

        const url = CONFIG.API.ROUTES.AGREEMENTS.LIST_COUNTRIES.replace('{agreementId}', this.agreementId)
        if (!this.isAllowedTo('SupplierCentral/listAgreementCountries')) {
            return
        }

        this.addJob(url)
        this.$api.post(url, {}).then(({ data }) => {
            this.$emit('fetched', data.data)
            this.items = data.data
        }).catch(err => {
            this.$root.$emit('notification:global', {
                message: err,
                type: 'error',
            })
        }).finally(() => {
            this.finishJob(url)
        })
    },
}
</script>
